.fillter__page li {
  font-size: 16px !important;
  display: flex;
  line-height: 25px;
}
.fillter__page li a {
  color: #777777 !important;

  padding-bottom: 6px;
  text-transform: capitalize;
}
.clear__fillters {
  padding: 20px 0;
}
.clear_button {
  width: 100%;
  cursor: pointer;
  background-color: #f15a29;
  color: white;
  padding: 10px 10px;
  text-align: center;
  border-radius: 7px;
  transition: 0.4s;
  display: inline-block;
}
.clear_button:hover {
  background-color: #f55622;
  color: white;
}
.cat__fillter {
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 30px;
}
.cat__fillter::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.cat__fillter::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.cat__fillter::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1d73be;
}
