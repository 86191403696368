@import "../../App.scss";

.ONM_img {
  min-height: 250px;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-width: 100%;
    height: 100%;
  }
}
.ONM_img a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.card-body {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px !important;
  position: relative;
  min-height: 125px;
}
.ONM_offer-container {
  margin-bottom: 0;
  margin-top: 10px;
  display: block;
}
.card-title-product {
  padding: 0px;
  text-align: left;

  a {
    font-size: 16px;
    font-weight: 400;
    color: #777;
  }
}
.ONM_review-star-product {
  background-color: rgba(255, 255, 255, 0.7);

  border-radius: 4px;

  color: #999;
}
.sale-price {
  font-size: 18px;
  color: #222;
  font-weight: 700;
  margin-bottom: 0;
}
.regular-price {
  font-size: 18px;
  color: #222;
  font-weight: 600;
  margin-bottom: 0;
}
.lable3 {
  border-radius: 100%;
  background-color: orangered;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  padding: 8px 6px;
  text-transform: uppercase;
  color: #fff;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.lable3 small {
  font-size: 7px;
}
.ribbon {
  font-size: 10px;
  text-align: center;
  background-color: #f15a29;
  color: white;
  padding: 0.4rem 6rem;
  /* font-size: 1.4rem; */
  text-transform: uppercase;
  position: absolute;
  top: -3%;
  left: -32%;
  transform: rotate(-45deg);
}
.ribbon span {
  display: block;
  margin-top: 17px;
  font-size: 13px;
}
