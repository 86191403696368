@import "../../App.scss";
.admin__active {
  color: #007bff;
}

.ONM_admin_left {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.1);

  li {
    list-style: none;
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding: 13px;
    cursor: pointer;
    display: block;
  }
}

.ONM_button_pending_merch {
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.ONM_banner_design {
  border: 1px solid #dddddd;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.ONM_row_design {
  border: 1px solid #dddddd;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.ONM_row_design-left {
  border-left: 1px solid #ddd;
}

.ONM_dotted_border {
  border: 1px dashed #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.ONM_icons {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 6px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  text-align: center;
  margin-right: 5px;
}

.ONM_modal_header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .ONM_button_pending_merch {
    margin-left: 0 !important;
  }
  .pagination {
    margin-top: 30px;
    float: none !important;
  }
}
