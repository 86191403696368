@import "../../App.scss";

.cart-table-wrap {
  padding: 10px 20px;
  background: #fafafa;
  width: 100%;
  overflow: auto;
}
.padding__class {
  padding: 70px 0;
}
.cart-table,
.wishlist-table {
  width: 100%;

  a {
    color: $main-color;
  }
  th {
    color: #222;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 0;
    text-align: center;
    padding: 0 0.75rem 0.75rem 0.75rem;
  }
  td,
  th {
    padding: 10px;
    width: auto;
  }
  th,
  tr:not(:last-child) td {
    border-bottom: 1px solid #ddd;
  }
  td {
    min-width: 210px;
    vertical-align: middle;
    color: #777;
    border-top: 0;
    border-bottom: 1px solid #eee !important;
    text-align: center;
  }
  .stockinput {
    text-align: center;
  }
  .item-stock {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trash {
    stroke: red !important;
  }
  .item-price h3 {
    color: #222;
    font-weight: 500;
  }
  .item-subtotal h3 {
    color: #222;
    font-weight: 500;
    color: #f15a29;
  }
  .item-img {
    text-align: center;
  }
  .item-img img {
    width: auto;
    max-height: 60px;
  }
  .item-title {
    font-size: 14px;
    // max-width:300px;
  }
  .item-price > span {
    // font-weight:bold;
  }
  .item-price .small-cap {
    font-size: 12px;
    margin-left: 10px;
    font-weight: normal;
    text-decoration: line-through;
  }
  .item-stock input[type="number"] {
    width: 70px;
  }
  .table-action {
    cursor: pointer;
    border-radius: 50px;
    display: inline-block;
    padding: 10px;
  }
  .table-action svg {
    display: block;
  }
  .table-action:hover {
    background: rgb(247, 247, 247);
  }
  .item-stock svg {
    width: 18px;
    margin: 5px;
    color: #b0b0b1;
  }
  .item-stock svg:hover {
    cursor: pointer;
  }
}

.cart-table {
  td:nth-child(1) {
    width: 10%;
  }
  td:nth-child(2) {
    width: 40%;
  }
  td:nth-child(3) {
    width: 15%;
  }
  td:nth-child(4) {
    width: 15%;
  }
  td:nth-child(5) {
    width: 15%;
  }
  td:nth-child(6) {
    width: 10%;
  }
}

.wishlist-table {
  td:nth-child(1) {
    width: 10%;
  }
  td:nth-child(2) {
    width: 50%;
  }
  td:nth-child(3) {
    width: 25%;
  }
  td:nth-child(4) {
    width: 20%;
  }
  td:nth-child(5) {
    width: 5%;
  }
}

.cart-total {
  table td:first-child {
    font-weight: bold;
    min-width: 100px;
  }
  .total-price {
    font-size: 24px;
    color: $orange-color;
  }
}

@media screen and (max-width: 767px) {
  .cart-table,
  .wishlist-table {
    .item-img {
      width: 100px;
    }
    .btn {
      width: 100px;
    }
    .item-title {
      width: 250px;
    }
    .item-price {
      width: 150px;
    }
    .item-stock {
      width: 130px;
    }
    .item-subtotal {
      width: 100px;
    }
  }
}
