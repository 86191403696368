@import "../../App.scss";

.product-title {
  color: $main-color;
}

.ONM_product-tabs {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
}

.ONM_review-card {
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 5px 10px;
  list-style: none;
}

.ONM_review-box {
  border-radius: 4px;
  padding: 50px 15px;
  background-color: $orange-color;
  color: white;
  text-align: center;
}

.ONM_review-box-first {
  font-size: 45px;
}

.ONM_review-count {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}

.ONM_tab-active {
  color: #1d73be !important;
  border-bottom: 1px solid #1d73be !important;
}

.ONM_price {
  font-size: 30px;
  color: $orange-color;
  font-weight: 700;
}

.ONM_offers {
  background-color: #e43a36;
  color: white;
  padding: 5px;
}

.ONM_strikethrough {
  text-decoration-line: line-through;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.ONM_icon_color-empty {
  color: rgba(0, 0, 0, 0.1);
}

.OMN-star-active {
  color: $orange-color;
}

.product-action {
  a {
    color: $main-color;
  }
  a:hover {
    color: orangered;
  }
  svg {
    margin-right: 5px;
    position: relative;
    top: -1.5px;
  }
  .cart-action {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .add-to-cart {
    border-radius: 0;
    background: $orange-color;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    text-transform: initial;
    width: auto;
  }
  .add-to-cart:hover {
    background: #d63b08;
    color: #fff;
  }
  .buy-now {
    border-radius: 0;
    background: $dark-color;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    text-transform: initial;
    width: auto;
  }
  .buy-now:hover {
    background: $main-color;
    color: #fff;
  }
  .wishlist-action {
    margin-bottom: 10px;
  }
  .add-wishlist svg {
    color: #ccc;
  }
  .add-wishlist:hover svg {
    color: orangered;
  }
  .add-wishlist {
    font-size: 17px;
  }
  .open-map svg {
    color: #ccc;
  }
  .open-map:hover svg {
    color: orangered;
  }
  .open-map {
    font-size: 17px;
  }
}
.storename {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
  color: #1b75bc;
}
.product-meta {
  margin-top: 40px;
  font-size: 16px;
}
