@import "../../App.scss";

.ONM_merchant_profile {
  padding: 15px;
  background-color: #fbfbfb;
  li {
    list-style: none;
    font-weight: 700;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    font-size: 15px;
  }

  :last-child {
    color: red;
  }
}
.merchant-form input {
  padding: 20px;
  font-size: 13px;
}
.merchant-form select {
  font-size: 14px;
}
.ONM_merchant_profile li:not(:last-child):after {
  content: "|";
  margin-left: 6px;
}

.ONM_merchant_header {
  text-align: center;
}

.ONM_login {
  border-radius: 8px;
  padding: 20px;
}

.ONM_login_border {
  border-right: 1px solid #ddd;
}
.btn-reset {
  padding: 11px 18px;
  background-color: orangered;
  color: white;
}
.btn-reset:hover {
  color: #fff;
}
.action__row {
  display: flex;
  align-items: center;
}
.action__row .icon__edit {
  color: #fbfbfb;
  background-color: darkorange;
}
.action__row .icon__delete {
  color: #fbfbfb;
  background-color: orangered;
}
.merchant_active {
  color: #1b75bc;
}

.ONM_login_otp {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 3px 12px 3px rgba(0, 0, 0, 0.4);
}

.ONM_icons {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 6px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  text-align: center;
  margin-right: 5px;
}

.ONM_close_icon {
  border-radius: 50%;
  background-color: #800020;
  fill: #fff;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}

.ONM-merchant_image {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 12px;
  width: 300px;
  height: 200px;
  overflow: hidden;
  text-align: center;

  img {
    object-fit: scale-down;
    max-height: 100%;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .ONM_login_border {
    border-right: none !important;
  }
}
