@import "../App.scss";

.delivery-address{
    font-size:12px;
    padding-left:10px;
    margin-bottom:5px;
    position:relative;

    svg{
        margin-left:10px;
    }
    svg:hover{
        cursor:pointer;
    }
}
.delivery-address:before{
    content:"-";
    position:absolute;
    left:0;
}