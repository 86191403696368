// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import "./App.scss";

body {
  margin: 0;
  font-family: "Montserrat";
  // font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 22px;
  color: $main-color;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 1.6rem !important;
  font-weight: 500;
  color: $orange-color;
  margin: 25px 0;
}

h2 {
  font-size: 1.4rem !important;
  padding: 5px 0;
  font-weight: 500;
  color: $orange-color;
  margin: 25px 0;
}

h3 {
  font-size: 1.4rem !important;
  padding: 5px;
  font-weight: 500;
  color: $main-color;
}

h4,
h5,
h6 {
  color: $main-color;
}

.btn {
  border-radius: 0;
  padding: 8px 8px;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.6875em;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  line-height: 15px;
}
.btn-dark {
  background: $dark-color;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  // background:$dark-color;
  box-shadow: none !important;
}
.btn-primary {
  background: $orange-color;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #d63b08 !important;
  border-color: #d63b08 !important;
  box-shadow: none !important;
}
.btn-primary:disabled,
.btn-primary:disabled:hover {
  background: #ccc !important;
  cursor: not-allowed;
}
.btn-action {
  font-size: 0.875em;
  padding: 14px 48px;
}
@media screen and (max-width: 767px) {
  .btn-block-xs {
    display: block;
    width: 100%;
  }
}
.form-group {
  label {
    font-size: 11px;
    text-transform: uppercase;
  }
  .alert {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 12px;
  }
}
.form-control {
  width: 100%;
  margin: 0;
  padding: 8px 8px;
  border: 0;
  outline: 1px solid #ddd;
  outline-offset: -2px;
  font-family: Montserrat, sans-serif;
  font-size: 0.75em;
  background-color: transparent;
  border-radius: 0;
  line-height: 1.6;
  height: calc(1.5em + 0.75rem + 4px);
}
.form-control:focus {
  outline: 2px solid $dark-color;
  border: 0;
  box-shadow: none;
}
.form-group .css-1s2u09g-control {
  border-radius: 0;
  height: calc(1.5em + 0.75rem + 4px);
  font-size: 0.75em;
  font-family: Montserrat, sans-serif;
  border: 2px solid #b0b0b1;
  min-height: calc(1.5em + 0.75rem + 4px);

  .css-319lph-ValueContainer {
    padding: 0 3px;
  }
  .css-tlfecz-indicatorContainer {
    padding: 5px;
  }
}
.form-group .css-1pahdxg-control .css-319lph-ValueContainer {
  padding: 0 3px;
}
.css-yk16xz-control,
.css-1pahdxg-control {
  border-radius: 0 !important;
  border: 2px solid #b0b0b1 !important;
  font-size: 0.75em !important;
  color: $main-color !important;
  height: calc(2.5em + 0.75rem + 4px);
  box-shadow: none !important;
  border: 2px solid #b0b0b1 !important;
  min-height: 0 !important;

  .css-jyvzvp-Xt {
    line-height: 14px !important;
  }
  .css-1wa3eu0-placeholder {
    top: 30% !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    position: absolute;
    right: 3px;
    top: 2px;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 5px !important;
  }
}
.css-1pahdxg-control {
  border: 2px solid $main-color !important;
}

.card {
  // -webkit-box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.04);
  // box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0;
  min-height: 442px;
  // max-height: 442px;
  overflow: hidden;
}

.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  object-fit: contain;
  width: 100%;
  // min-height: 100%;
  // height: 100%;
}

.card-title {
  font-size: 14px;
  color: $main-color;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  padding-right: 50px;
  padding-left: 0;
}

.card-title a {
  color: $main-color;
}

.card-body {
  position: relative;
  flex: none;
}

.card-text {
  font-size: 14px;
  color: $main-color;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  padding: 0;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

// .active {
//   background-color: $background-header-color !important;
//   color: white !important;
// }

.active_anchor {
  color: $background-header-color !important;
}

.ONM_offer-filter {
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  color: $main-color;
}

.ONM_offer-filter:hover {
  background-color: $background-header-color;
  color: white;
}

.ONM_clear-filter {
  background-color: $background-header-color;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.ONM_clear-filter:hover {
  color: white;
}

.ONM_cursor-pointer {
  cursor: pointer;
}

.banner-preview {
  max-width: 350px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.ONM_card-icons-container {
  position: absolute;
  right: 30px;
  top: 15px;
}

.ONM_offer-container {
  // border-top: 1px solid #ddd;
  padding-top: 6px;
  padding-left: 0;
  padding-right: 0;
}

.ONM_offer-text {
  background-color: #fff6f5;
  padding: 3px 6px;
  font-weight: 700;
  font-size: 22px;
  border-radius: 2px;
  color: #ef534e;
}

.ONM_review-star {
  position: absolute;
  bottom: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px 10px;
  left: 15px;
  border-radius: 4px;
  font-size: 0.8125em;
  line-height: 1;
  color: #999;
}

.ONM_banner-container {
  max-height: 296px;
  min-height: 296px;
  overflow: hidden;
}

.ONM_banner-container-parent {
  // background-color: rgba(0, 0, 0, 0.1);
}

.carousel-slider {
  // max-height: 300px !important;
}

.page-link {
  z-index: 0 !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999;
}

table {
  font-size: 14px;
}

textarea {
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
}

.ONM_relative {
  position: relative;
  // padding-top:111px;
}

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.close-modal button {
  background: none;
  border: none;
  font-size: 28px;
}
.close-modal button:hover {
  font-weight: bold;
}
.card.product {
  .card-body {
    // flex: 0.4 1 auto;
  }
  h4 {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
    padding-right: 0;
    min-height: 34px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .product-act {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .add-wishlist {
    color: #ccc;
    font-size: 20px;
    cursor: pointer;
    display: inline;
  }
  .add-wishlist:hover {
    color: rgba(207, 22, 22, 0.719);
  }
  .add-to-cart {
    border-radius: 0;
    background: $orange-color;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    width: 100%;
    text-transform: initial;
  }
  .add-to-cart:hover {
    background: #d63b08;
    color: #fff;
  }
  .add-to-cart svg {
    margin-right: 5px;
    display: none;
    font-size: 20px;
  }
  .sale-price,
  .regular-price {
    font-size: 14px;
  }
  .small-cap {
    font-size: 12px;
    margin-left: 10px;
    text-decoration: line-through;
  }
  .discount {
    margin-left: 10px;
    font-weight: normal;
    color: #ef534e;
  }
  .sold-out {
    border-radius: 0;
    background: #ccc;
    color: #000;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    padding: 4px;
  }
}
.page-header {
  width: 100%;
  display: block;
  padding: 20px 0;

  h1 {
    text-align: center;
    font-size: 30px !important;
    text-transform: uppercase;
    color: $main-color;
    font-weight: 600;
    line-height: 42px;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 8px;
  }
}
@media screen and (min-width: 991px) {
  .content-container {
    min-height: 250px;
  }
}
@media screen and (max-width: 992px) {
  .ONM_banner-container {
    min-height: 0;
  }
  .ReactModal__Content {
    width: 90% !important;
  }
}
