$background-header-color: #1d73be;
$background-subheader-color: #1d73be;
$link-color: #1c75bc;
$main-color: #3b3a3d;
$orange-color: #f15a29;
$dark-color: #303741;

@mixin headerTagStyle {
  font-weight: 500;
  color: $orange-color;
  margin: 25px 0;
}

@mixin ONM_ads-second {
  min-height: 442px;
  max-height: 442px;
  overflow: hidden;
  border: 1px solid #ddd;
}

@mixin ONM_ads-second-middle {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
  border: 1px solid #ddd;
}

@mixin ONM_ads-second-center {
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
}
