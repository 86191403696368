.menu__content__lists {
  list-style: none;
}
.menu__content__list {
  display: block;
  margin-bottom: 8px;
}
.sub__head {
  font-weight: 700;
}
.menu__content__list a {
  color: black !important;
}
.overflowstyle {
  overflow: hidden;
  overflow-y: scroll;
  height: 400px;
}
