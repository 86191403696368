.content__store {
  position: relative;
}

.label3 {
  border-radius: 100%;
  background-color: #1b75bc;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 12px 6px;
  text-transform: uppercase;
  color: #fff;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.map__review {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.map__review__store {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.on__hover {
  transition: 0.4s;
  padding: 20px;
}
.on__hover:hover {
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.075);
}
.on__hover:hover h4 {
  color: orangered;
}
