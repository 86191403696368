:root {
  --theme-deafult: #1b75bc;
  // This gradient color only for gym layout
  --theme-gradient1: #01effc;
  --theme-gradient2: #485ff2;
}

@import "../scss/landing_page.scss";
@import "../scss/animate.scss";
@import "~react-image-lightbox/style.css";

@import "~react-toastify/dist/ReactToastify.css";
@import "font-awesome.scss";
@import "theme/variables";
@import "theme/style";
@import "theme/menu";
@import "theme/responsive";

@import "theme/rtl";
@import "theme/dark";

@import "./slick.scss";
@import "./slick-theme.scss";
