@import "../../App.scss";

.sidebar {
  // border: 1px solid #ddd;
  // border-radius: 5px;

  ul {
    list-style: none;
  }

  .product-filter {
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
  }

  .product-filter h3 {
    margin: 0;
    color: $main-color;
    font-size: 14px !important;
    font-weight: bold;
  }
  .product-filter .section:first-child {
    border-top: 1px solid #dedede;
  }
  .product-filter .section {
    border-bottom: 1px solid #dedede;
  }
}

.ONM_sub-category-box {
  border: 1px solid #ddd;
  margin-right: 5px;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.ONM_menu-header:hover .dropdown-menu {
  display: block;
}

.navbar-nav li:last-child .dropdown-menu {
  left: auto;
  right: 0;
}

.navbar-nav li:nth-last-child(2) .dropdown-menu {
  left: auto;
  right: 0;
}

.ONM_menu-header .nav-link {
  color: $main-color !important;
}
.ONM_cat_header {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid #ddd;
}

.ONM_left-filters {
  li {
    list-style: none;
  }
}

.ONM_cat_header_dropdown {
  float: left;
  overflow: hidden;
  z-index: 9999;
}

.ONM_cat_header_dropdown_anchor {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  padding: 0 15px;
  font-weight: 500;
}
.ONM_cat_header_dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}

.ONM_cat_header_dropdown:hover .ONM_cat_header_dropdown-content {
  display: block;
  padding: 15px 32px;
}

.ONM_cat_header_anchor {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
}

.ONM_cat_min_width {
  margin-left: 20px;
}

.ONM_child_cat_filter {
  color: rgba(0, 0, 0, 0.6);
}

.ONM_loaders {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.ONM_loader_icon {
  text-align: center;
  color: #ffffff;
}

.ONM_react_loader {
  position: fixed;
  left: 45%;
  top: 35%;
}

.pagination {
  justify-content: center;
}
.pagination li {
  padding: 0 2px;
}
.pagination li a {
  font-size: 12px;
  padding: 5px 10px;
}
div .pagination li.active {
  background: none !important;
  border-radius: 20px !important;
}
div .pagination li.active a {
  background: #1d73be !important;
  font-weight: bold !important;
}
