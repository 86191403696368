@import "../../App.scss";
.review-order-wrap{
    padding:20px 20px;    
    background:#fafafa;
    width: 100%;
}
.review-order-table{
    width:100%;

    th:not(:first-child), td:not(:first-child){
        padding-left:10px;
        text-align:right;
    }
    th,td{
        padding-top:5px;
        padding-bottom:5px;
        width:50%;
    }
    .total-wrap td, .total-wrap th{
        padding-top:20px;
    }
    .total-price{
        font-weight:bold;
        font-size: 24px;
        color: $orange-color;
    }
}
.shipping-form{
    h3{
        color: $main-color;
        margin-bottom:20px;
    }
}