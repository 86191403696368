@import "../../App.scss";
.justify-content-center {
  display: flex;
}
.offer-rounded {
  height: 150px;
  width: 150px;
  vertical-align: middle;
  background-color: $background-header-color;
  color: white;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertical */
  justify-content: center; /* Horizontal */
  line-height: 1.3;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.box-banner:hover,
.right-banner:hover {
  transform: scale(1.05);
  transition: all 0.5s ease;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .offer-rounded {
    height: 125px;
    width: 125px;
  }
}

.ONM_icon_color {
  color: $background-header-color;
  opacity: 0.7;
}

.ONM_view_offer a {
  background-color: $link-color;
  padding: 5px 15px;
  margin-left: 25px;
  font-size: 16px;
  color: white !important;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.ONM_img {
  max-height: 250px;
  overflow: hidden;
}

.ONM_img img {
  max-width: 100%;
}

.ONM_fashion-ads {
  @include ONM_ads-second;
}

.main-banners img {
  margin: 0 auto;
  display: block;
  object-fit: contain;
  width: 100%;
  min-height: 100%;
}
